import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect, useCallback } from 'react'

import Alert from '../../components/alert';
import UTIcon from '../../components/icons';
import Button from '../../components/button';
import Editor from '../../components/editor';
import { useAuthContext } from '../../context/auth';
import TextInput from '../../components/Fields/Text';
import useWindowSize from '../../hooks/useWindowSize';
import { useCourseContext } from '../../context/course';
import { usePartnerContext } from '../../context/partner';
import { useMessageContext } from '../../context/message';
import ServiceDeskService from '../../services/service-desk';
import { uploadImagesAndReplaceInMessage } from '../../components/editor/utils';

type Props = {
    emailForm: boolean;
    setEmailForm: (emailForm: boolean) => void;
}

export default function NewEmailBar({ emailForm, setEmailForm }: Props) {

    const { student } = useAuthContext();

    if (!student) return (<></>);

    return (
        <>
            <NewMessageModal vis={emailForm} setVis={setEmailForm} />
        </>
    )
}

const NewMessageModal = ({ vis, setVis }: {
    vis: boolean;
    setVis: (vis: boolean) => void;
}) => {

    const { t } = useTranslation();
    const { newMessage } = useMessageContext();
    const { course, module, path } = useCourseContext();
    const { isMobile } = useWindowSize();

    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");

    const { partner, mailbox, colors } = usePartnerContext();
    const { student } = useAuthContext();

    const handleSend = async () => {

        if (!student) {
            console.warn("No student found to send message from");
            return;
        }

        if (!partner) {
            console.warn("No partner found to send message to");
            return;
        }

        if (!mailbox) {
            console.warn("No mailbox found for partner");
            return;
        }

        const content = await uploadImagesAndReplaceInMessage(message, mailbox.id);

        if (content === false) {
            newMessage('danger', 'Failed to upload images. Aborted sending email.');
            return;
        }

        const originUrl = window?.location?.href;

        const response = await ServiceDeskService.Message.startConversation({
            mailboxId: mailbox.id,
            contact: {
                id: student.id,
                name: `${student.firstName} ${student.lastName}`,
                email: student.email,
                firstName: student.firstName,
                lastName: student.lastName
            },
            content,
            subject,
            originUrl
        });

        if (!response.success || !response.data) {
            newMessage('danger', 'Failed to send email');
            return;
        };

        newMessage('success', 'Message sent to the Instructor successfully.');
        setVis(false);
        setMessage("");
        setDefaultSubjectAndContent();
    }

    const setDefaultSubjectAndContent = useCallback(() => {
        let sj = document?.title || path;

        if (course) {
            sj = `${course.name}`;
        }
        if (module) {
            sj = `${sj} - ${module.name}`;
        }
        setSubject(sj);
    }, [course, module, path])

    useEffect(() => {
        setDefaultSubjectAndContent();
    }, [setDefaultSubjectAndContent])

    const flyInLeft = useMemo(() => {
        return vis ? 0 : -650;
    }, [vis]);

    const headerHeight = useMemo(() => {
        return document.querySelector('header')?.clientHeight || 0;
    }, []);

    useEffect(() => {
        const value = vis ? 'hidden' : 'auto';
        document.querySelector('body')?.setAttribute('style', `overflow: ${value};`);
        return () => {
            // set window overflow auto
            document.querySelector('body')?.setAttribute('style', 'overflow: auto;');
        }
    }, [vis])

    return <div className={"fixed py-5 px-10 space-y-5 h-full z-50"} style={{
        width: isMobile ? "100vw" : 600,
        backgroundColor: colors?.white,
        borderColor: colors?.gray2,
        top: `${headerHeight}px`,
        right: `${flyInLeft}px`,
        transition: 'right 0.2s',
        borderLeft: '1px solid #e5e5e5'
    }}>
        <div className={"flex justify-end md:absolute top-10 left-[-34px]"}>
            <Button variant={"gray"} noShadow className={"w-[34px] h-[34px]"} padding={"p-0"} onClick={() => {
                setVis(false)
            }}>
                <UTIcon type={"close"} color={colors.textColor} size={16} />
            </Button>
        </div>
        <div className={""}>

            {!mailbox?.id && (
                <Alert icon={"warning"} color={"warning2"} iconColor={"warningText"}>
                    {t("noMailboxFound")}
                </Alert>
            )}

            {mailbox?.id && (
                <div>
                    <div className={"text-black space-y-3"}>
                        <div className={"flex flex-row justify-between"}>
                            <h2 className={"text-2xl font-bold"}>
                                {t("emailInstructor")}
                            </h2>
                        </div>
                        <TextInput
                            label={"Subject"}
                            value={subject}
                            placeholder={"Subject"}
                            onChange={(e) => {
                                setSubject(e.target.value);
                            }}
                        />
                        <Editor
                            value={message}
                            onChange={setMessage}
                            style={{ maxHeight: "calc(-330px + 100vh)" }}
                        />
                    </div>
                </div>
            )}
            <div className={"flex justify-end mt-4"}>
                <Button onClick={handleSend}>
                    Send
                </Button>
            </div>
        </div>
    </div>
}