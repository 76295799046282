import React, { useEffect } from 'react'

import AppHeader from './Header';
import AppNavigation from './Navigation';
import Loader from '../../components/loader';
import { useAuthContext } from '../../context/auth';
import { usePartnerContext } from '../../context/partner'

type Props = {
    children: React.ReactNode;
};

export default function AppLayout({ children }: Props) {

    const [path, setPath] = React.useState<string | undefined>(undefined);
    const { loading, student } = useAuthContext();
    const { colors } = usePartnerContext();

    useEffect(() => {
        setPath(window?.location?.pathname);
    }, [window.location.pathname]);

    const isAuthPage = path?.includes("/auth");
    const redirectingToAuth = !loading && !student;

    if (isAuthPage) {
        return <>{children}</>
    }

    if (loading || !path || redirectingToAuth) {
        return <div className={"h-[100vh] flex items-center justify-center"}>
            <Loader />
            <div className={"hidden"}>
                {children}
            </div>
        </div>
    };

    return (
        <div
            className={"flex flex-col"}
            style={{
                minHeight: '100vh',
                backgroundColor: colors.backgroundGray,
                color: colors.textColor
            }}>
            <AppHeader />
            <div className={"md:flex md:flex-1"}>
                <AppNavigation />
                <main className={"flex-1 pb-[60px]"}>
                    {children}
                </main>
            </div>
        </div>
    )
}

